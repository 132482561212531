exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-paletyzatory-tsx": () => import("./../../../src/pages/paletyzatory.tsx" /* webpackChunkName: "component---src-pages-paletyzatory-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-sektory-linie-do-pakowania-w-sektorze-spozywczym-tsx": () => import("./../../../src/pages/sektory/linie-do-pakowania-w-sektorze-spozywczym.tsx" /* webpackChunkName: "component---src-pages-sektory-linie-do-pakowania-w-sektorze-spozywczym-tsx" */),
  "component---src-pages-sektory-paletyzacja-robotem-w-przemysle-tsx": () => import("./../../../src/pages/sektory/paletyzacja-robotem-w-przemysle.tsx" /* webpackChunkName: "component---src-pages-sektory-paletyzacja-robotem-w-przemysle-tsx" */),
  "component---src-pages-sektory-paletyzacja-w-ogrodnictwie-tsx": () => import("./../../../src/pages/sektory/paletyzacja-w-ogrodnictwie.tsx" /* webpackChunkName: "component---src-pages-sektory-paletyzacja-w-ogrodnictwie-tsx" */),
  "component---src-pages-sektory-systemy-paletyzacji-warzyw-i-owocow-tsx": () => import("./../../../src/pages/sektory/systemy-paletyzacji-warzyw-i-owocow.tsx" /* webpackChunkName: "component---src-pages-sektory-systemy-paletyzacji-warzyw-i-owocow-tsx" */)
}

